import { centerStyle } from '@assets/styles/styles'
import { theme } from '@core/styles'
import styled from '@emotion/styled'

export const FileAttachmentsPreviewContainer = styled.div`
  position: relative;
  margin-bottom: 40px;
`

export const ItemContainer = styled.div`
  height: 400px;
  background-color: ${theme.colors.LIGHT_GRAY};
  width: 100%;
  border-radius: 25px;
  overflow: hidden;
  ${centerStyle}
`

export const VideConatiner = styled.div`
  width: 100%;
`

export const ImageContainer = styled.div`
  width: 100%;
  ${centerStyle}
`

export const AudioContainer = styled.div`
  width: 100%;
  ${centerStyle}
`
